export default function () {
  const { $yotpo, $ketch } = useNuxtApp()
  const { isIntegrationEnabled } = useUtils()

  const showConsentWarning = ref(false)

  onMounted(() => {
    if (isIntegrationEnabled('ketch')) {
      $ketch.onConsentResolved(() => {
        showConsentWarning.value = !$ketch.hasConsent.value
        if ($ketch.hasConsent.value)
          setTimeout(() => {
            $yotpo.addCallback(() => {
              $yotpo.refresh()
            })
            $yotpo.load()
          }, 0)
      })
    } else {
      $yotpo.addCallback(() => {
        $yotpo.refresh()
      })
      $yotpo.load()
    }
  })

  return {
    showConsentWarning,
  }
}
